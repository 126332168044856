import {
  Box,
  CircularProgress,
  Snackbar,
  Button,
  Stack,
  Alert,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";

function ForgotPasswordEmail() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [validationObj, setValidationObj] = useState({
    email: {
      error: false,
      message: "",
    },
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const sendEmail = async (data) => {
    setLoading(true);
    setMessage("");
    setError("");
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/forgot`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let res = await response.json();
      if (res.status) {
        setLoading(false);
        setMessage("Email sent successfully");
        setOpen(true);
      } else {
        setLoading(false);
        setError("Error sending email");
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setError("Error sending email");
      setOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (data) => {
      if (checkHandler()) {
        try {
          sendEmail(data);
        } catch (err) {
          console.log(err);
        }
      } else {
        setError(true);
        setOpen(true);
      }
    },
  });

  const checkHandler = () => {
    for (let i in validationObj) {
      if (validationObj[i].error || formik.values[i] === "") {
        setMessage("Please fill all the fields correctly");
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    let error = "";
    let message = " ";
    let name = e.target.name;
    let value = e.target.value;
    if (name === "email") {
      if (!value) {
        error = true;
        message = "Email is required";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        error = true;
        message = "Email is invalid";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@(vitstudent.ac.in)$/)) {
        error = true;
        message = "Email should be of VIT Student";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, email: { error, message } });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: matches ? "center" : "flex-start",
        justifyContent: "center",
        padding: "1.5rem",
        backgroundColor: matches ? "none" : "#F7F7F7",
        borderRadius: "1rem",
        height: "fit-content",
        width: matches ? "25em" : "100%",
        margin: "auto",
        boxSizing: "border-box",
      }}
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="on"
    >
      <Stack
        sx={{
          alignItems: "flex-start",
          mt: matches ? 0 : "125px",
          width: "100%",
        }}
      >
        {/* Form Heading */}
        <Box sx={{ mt: matches ? 0 : "125px", mb: 2, mr: "auto" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "30px",
              fontWeight: 700,
              color: "#39C7A5",
            }}
          >
            Forgot Password?
          </Typography>
          <Typography
            sx={{ textAlign: "left", fontSize: "18px", fontWeight: 600 }}
          >
            Enter your email
          </Typography>
        </Box>

        {/* Email ID */}
        <TextField
          error={validationObj.email.error}
          helperText={
            validationObj.email.error ? validationObj.email.message : ""
          }
          required
          id="email"
          name="email"
          label="Email ID"
          placeholder="Enter your email ID"
          onChange={handleChange}
          value={formik.values.email}
          sx={{ width: "100%", mb: 2 }}
        />

        {/* Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="medium"
            sx={{ width: "100%", color: "#fff" }}
            disabled={loading}
          >
            {loading && (
              <>
                <CircularProgress thickness={6} color="inherit" size="1.2rem" />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Sending Email...
                </Typography>
              </>
            )}
            {!loading && "SEND EMAIL"}
          </Button>
        </Box>
      </Stack>
      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default ForgotPasswordEmail;
