import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [show, setShow] = useState(false);
  const [verified, setVerified] = useState(true);

  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleShowPassword = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resendEmail = async () => {
    setLoading2(true);
    setError("");
    setMessage("");
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/resend`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formik.values.email,
        }),
      });
      let data = await response.json();
      console.log(data);
      if (data.status) {
        setMessage(data.message);
        setOpen(true);
        setLoading2(false);
      } else {
        setError(data.message);
        setOpen(true);
        setLoading2(false);
      }
    } catch (error) {
      setError("Something went wrong");
      setOpen(true);
      setLoading2(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setError(false);
      setMessage("");
      setVerified(true);
      if (values.email === "" || values.password === "") {
        setError(true);
        setMessage("Fill all fields");
        setOpen(true);
        return;
      } else if (!values.email.match(/^[\w-.]+@(vitstudent.ac.in)$/)) {
        setOpen(true);
        setError(true);
        setMessage("Invalid Email");
      } else {
        setLoading(true);
        let url = `https://${process.env.REACT_APP_BASE_URL}/user/login`;
        try {
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status) {
                setOpen(true);
                setLoading(false);
                setMessage("Login Successful");
                setError(false);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("user", JSON.stringify(data.user));
                setTimeout(() => {
                  navigate("/home");
                }, 1000);
              } else if (data.msg) {
                if (data.msg === "Email not found") {
                  setOpen(true);
                  setLoading(false);
                  setMessage(data.msg);
                  setError(true);
                  setTimeout(() => {
                    navigate("/form/register");
                  }, 1000);
                  return;
                }
                if (!data.verified) {
                  setVerified(false);
                }
                setOpen(true);
                setLoading(false);
                setMessage(data.msg);
                setError(true);
              } else {
                setError("Something went wrong. Please try again later.");
                setOpen(true);
              }
            })
            .catch((err) => {
              setError("Something went wrong. Please try again later.");
              setOpen(true);
              setLoading(false);
              //   console.log(err);
            });
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }
    },
  });

  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("refreshToken") && localStorage.getItem("user")) {
      gettoken().then((res) => {
        if (res) {
          navigate("/home");
        } else {
          setLoader(true);
        }
      });
    } else {
      setLoader(true);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && (
        <>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1.5rem",
              backgroundColor: matches ? "none" : "#F7F7F7",
              borderRadius: "1rem",
              height: "fit-content",
              width: matches ? "25em" : "100%",
              margin: "auto",
              boxSizing: "border-box",
            }}
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="on"
          >
            {/* Form Heading */}
            <Box sx={{ mt: matches ? 0 : "125px", mb: 4, mr: "auto" }}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "30px",
                  fontWeight: 700,
                  color: "#39C7A5",
                }}
              >
                Welcome back!
              </Typography>
              <Typography
                sx={{ textAlign: "left", fontSize: "18px", fontWeight: 600 }}
              >
                Sign in to your account
              </Typography>
            </Box>

            {/* Email */}
            <TextField
              required
              id="email"
              name="email"
              label="Email ID"
              placeholder="Enter your email ID"
              onChange={formik.handleChange}
              value={formik.values.email}
              autoFocus
              autoComplete="email"
              sx={{ width: "100%", mb: 2 }}
            />

            {/* Password */}
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              placeholder="Enter your password"
              type={show ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.password}
              autoComplete="current-password"
              sx={{ width: "100%", mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* RESEND BUTTON */}
            {!verified && (
              <Alert
                severity="warning"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button size="small" color="warning" onClick={resendEmail}>
                  {!loading2 && "Resend Verification Link"}
                  {loading2 && (
                    <>
                      <CircularProgress
                        thickness={6}
                        color="inherit"
                        size="1.2rem"
                      />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        Sending Mail..
                      </Typography>
                    </>
                  )}
                </Button>
              </Alert>
            )}

            {/* Forgot Password */}
            <Link
              href="./forgotPassword/Email"
              variant="body2"
              sx={{
                mb: 2,
                ml: "auto",
                color: "#000",
                fontWeight: 500,
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              Forgot Password?
            </Link>

            {/* Submit and Reset */}
            <Button
              type="submit"
              variant="contained"
              size="medium"
              disabled={loading}
              sx={{ color: "#fff", mb: 2, width: "100%", height: "3rem" }}
            >
              {loading && (
                <>
                  <CircularProgress
                    thickness={6}
                    color="inherit"
                    size="1.2rem"
                  />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    Logging In...
                  </Typography>
                </>
              )}
              {!loading && (
                <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                  LOGIN
                </Typography>
              )}
            </Button>

            {/* Snackbar */}
            {!loading && (
              <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  onClose={handleClose}
                  severity={error ? "error" : "success"}
                  sx={{ width: "100%" }}
                >
                  {message ? message : error}
                </Alert>
              </Snackbar>
            )}

            {/* New User */}
            <Link
              href="./register"
              variant="body2"
              sx={{
                mb: 2,
                color: "#000",
                fontWeight: 500,
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              First time? Register Here
            </Link>
          </Box>
        </>
      )}
      {!loader && (
        <>
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
              margin: "auto",
              display: matches ? "none" : "flex",
            }}
          >
            <CircularProgress size={70} />
          </Box>
          <CircularProgress
            size={70}
            sx={{ display: matches ? "inline-block" : "none" }}
          />
        </>
      )}
    </>
  );
}

export default Login;
