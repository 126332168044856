import React from "react";

function BottomSquare() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 182 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="182"
        y="325"
        width="326"
        height="325"
        transform="rotate(-180 182 325)"
        fill="#B7DBD3"
      />
      <rect
        x="125"
        y="379"
        width="326"
        height="325"
        transform="rotate(-180 125 379)"
        fill="#69D3BB"
      />
    </svg>
  );
}

function TopSquare() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 151 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <rect y="-169" width="326" height="325" fill="#8DDAC7" />
        <rect x="57" y="-223" width="326" height="325" fill="#39C7A5" />
      </g>
    </svg>
  );
}

export { TopSquare, BottomSquare};
