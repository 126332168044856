import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function VerifyPage() {
  const [verified, setVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const token = searchParams.get("token");

  const verify = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/verify`;
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      let data = await response.json();
      console.log(data);
      if (data.status) {
        setVerified(true);
        setOpen(true);
        setMessage("Email verified successfully");
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        setVerified(true);
        setOpen(true);
        setError(true);
        setMessage("Email verification failed. Please try again later.");
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    } catch (err) {
      setOpen(true);
      setError(true);
      setMessage("Something went wrong. Please try again later.");
      console.log(err);
    }
  };

  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!verified && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
            margin: "auto",
          }}
        >
          <CircularProgress size={70} />
        </Box>
      )}

      {/* Snackbar */}
      {verified && (
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default VerifyPage;
