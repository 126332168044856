import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "./Layout/Navbar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Product() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [product, setProduct] = useState({});
  const [msg, setMsg] = useState("");
  const [date, setDate] = useState(null);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const id = searchParams.get("id");
  const user = JSON.parse(localStorage.getItem("user"))[0];

  const getProduct = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/product/findProduct?id=${id}`;
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      if (data.status) {
        setProduct(data.data);
        const [year, month, date] = data.data.purchaseDate
          .split("T")[0]
          .split("-");
        setDate(`${date} ${months[month - 1]} ${year}`);
        setMsg(
          `Hi, this is ${user.firstName} ${user.lastName}. I am interested in your ${data.data.productName} for ₹${data.data.productPrice}.`
        );
        setLoader(true);
      } else {
        setOpen(true);
        setMessage(data.message);
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      }
    } catch (error) {
      setOpen(true);
      setMessage("Something went wrong. Please try again later");
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    }
  };

  useEffect(() => {
    getProduct();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!loader && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
            margin: "auto",
          }}
        >
          <CircularProgress size={70} />
        </Box>
      )}
      {loader && (
        <>
          <Navbar />
          <Stack
            direction={matches ? "row" : "column"}
            spacing={2}
            sx={{ m: 2, p: 2 }}
          >
            <Box
              sx={{
                width: matches ? "50%" : "100%",
                // height: matches ? "50%" : "100%",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <img
                src={product.imgUrl}
                alt={product.productName}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              sx={{
                width: matches ? "50%" : "100%",
                // height: matches ? "50%" : "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography fontSize={35}>{product.productName}</Typography>
              <Typography fontSize={25}>
                &#8377; {product.productPrice}
              </Typography>
              <Typography variant="subtitle1">
                Category: {product.category}
              </Typography>
              <Divider flexItem sx={{ borderColor: "#878787" }} />
              <Typography fontSize={20} fontWeight={500} sx={{ mt: 1 }}>
                Description:{" "}
              </Typography>
              <Typography variant="body1">{product.productDesc}</Typography>
              <Typography fontSize={20} fontWeight={500} sx={{ mt: 2 }}>
                Reason for selling:{" "}
              </Typography>
              <Typography variant="body1">{product.reason}</Typography>
              <Typography fontSize={20} fontWeight={500} sx={{ mt: 2 }}>
                Purchase Date:
              </Typography>
              <Typography variant="body1">{date}</Typography>
              <Button
                href={`https://wa.me/${
                  product.User.mobileNumber
                }?text=${encodeURIComponent(msg)}`}
                target="_blank"
                variant="contained"
                startIcon={<WhatsAppIcon />}
                sx={{ mt: 2 }}
              >
                Contact Seller
              </Button>
            </Box>
          </Stack>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Product;
