import React, { useState, useEffect } from "react";
import Navbar from "./Layout/Navbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Stack,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

function MyListings() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [toDeleteName, setToDeleteName] = useState(null);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  var token = "";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        token = data.accessToken;
      } else {
        navigate("/form/login");
      }
    } catch {
      navigate("/form/login");
    }
  };

  const getProducts = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/product/findUserProduct`;
    try {
      await gettoken();
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      if (data.status) {
        setProducts(data.data);
        setLoader(true);
      } else {
        setProducts([]);
        setLoader(true);
      }
    } catch (err) {
      setProducts([]);
      setLoader(true);
    }
  };

  const deleteDialog = (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle id="confirm-dialog">
        Do you want to delete {toDeleteName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting a listing is not reversable. The listing shall be lost
          forever.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpenDialog(false)}
          color="error"
        >
          No
        </Button>
        <Button
          variant="outlined"
          disabled={loading}
          onClick={() => {
            setOpenDialog(false);
            deleteProduct(toDeleteId);
          }}
        >
          {loading && (
            <>
              <CircularProgress thickness={6} color="inherit" size="1.2rem" />
              <Typography variant="subtitle2" style={{ marginLeft: "0.5rem" }}>
                Deleting Product...
              </Typography>
            </>
          )}
          {!loading && "YES"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmDelete = (id, name) => {
    setToDeleteId(id);
    setToDeleteName(name);
    setOpenDialog(true);
  };

  const deleteProduct = async (id) => {
    setError(false);
    setLoading(true);
    setMessage("");
    let url = `https://${process.env.REACT_APP_BASE_URL}/product/deleteProduct?id=${id}`;
    try {
      await gettoken();
      let response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      if (data.status) {
        setLoading(false);
        setOpenDialog(false);
        setMessage("Product Deleted Successfully");
        setOpen(true);
        getProducts();
      } else {
        setLoading(false);
        setOpenDialog(false);
        setMessage("Error Deleting Product");
        setError(true);
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setOpenDialog(false);
      setMessage("Error Deleting Product");
      setError(true);
      setOpen(true);
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && (
        <>
          <Navbar />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Seach…"
              inputProps={{ "aria-label": "search" }}
              sx={{ width: "100%" }}
            />
          </Search> */}
          <Stack direction={matches ? "row" : "column"}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                pt: 2,
                pr: matches ? 2 : 0,
                mx: matches ? 8 : 0,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  mx: 1,
                  mb: 4,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textAlign: matches ? "left" : "center",
                    fontWeight: 400,
                    fontSize: "32px",
                  }}
                >
                  My Products
                </Typography>

                {matches ? (
                  <Button
                    variant="contained"
                    sx={{ mr: 2, color: "#fff" }}
                    onClick={() => navigate("/form/listProduct")}
                  >
                    Add New Product
                  </Button>
                ) : (
                  <IconButton
                    variant="contained"
                    sx={{ mr: 1, p: 0 }}
                    // sx={{ position: "absolute", right: 0, mr: 1 }}
                    onClick={() => navigate("/form/listProduct")}
                  >
                    <PlaylistAddIcon fontSize="large" />
                  </IconButton>
                )}
              </Stack>
              <Grid container columns={{ xs: 2, sm: 3, md: 4 }}>
                {products.map((item) => (
                  <Grid item xs={1} key={item.id}>
                    <Card
                      sx={{
                        height: "auto",
                        m: 1,
                        borderRadius: "15px",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          zIndex: 99,
                          position: "absolute",
                          width: "100%",
                          mx: "auto",
                          height: "220px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: item.isVerified ? "none" : "flex",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: "red", textAlign: "center"}}
                        >
                          NOT VERIFIED
                        </Typography>
                      </Box>
                      <CardMedia
                        component="img"
                        height="140"
                        image={item.imgUrl}
                        alt="Product Image"
                        sx={{ opacity: item.isVerified ? 1 : 0.3 }}
                      />
                      <CardContent
                        sx={{ height: 80, opacity: item.isVerified ? 1 : 0.3 }}
                      >
                        <Typography component="div" fontSize={20}>
                          {item.productName.length > 14
                            ? `${item.productName.substr(0, 14)}...`
                            : `${item.productName}`}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.category}
                        </Typography>
                        <Typography variant="h6" component="div">
                          &#8377;{item.productPrice}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          height: 50,
                          justifyContent: "space-between",
                          pb: 0,
                        }}
                      >
                        <Link
                          underline="none"
                          onClick={(e) => {
                            localStorage.setItem(
                              "product",
                              JSON.stringify(item)
                            );
                            navigate(`/form/editProduct`);
                          }}
                        >
                          <Button size="small">Edit Details</Button>
                        </Link>
                        <IconButton
                          size="large"
                          color="error"
                          onClick={() => {
                            confirmDelete(item.id, item.productName);
                          }}
                          sx={{ p: 0 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
          {deleteDialog}
        </>
      )}
      {!loader && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
            margin: "auto",
          }}
        >
          <CircularProgress size={70} />
        </Box>
      )}

      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default MyListings;
