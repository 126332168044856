import React from "react";

function CircleWithTriangle() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 167 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <circle cx="113.158" cy="121.84" r="110.842" fill="#39C7A5" />
        <path
          d="M63.512 22.9227L44.4277 69.2741L13.8283 29.5709L63.512 22.9227Z"
          fill="#179572"
        />
      </g>
    </svg>
  );
}

export default CircleWithTriangle;
