import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "./Register";
import Login from "./Login";
import EditProfilePage from "./EditProfilePage";
import UpdatePassword from "./UpdatePassword";
import ListProduct from "./ListProduct";
import EditProduct from "./EditProduct";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordPassword from "./ForgotPasswordPassword";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TriangleWithCircle from "../Layout/TriangleWithCircle";
import { BottomSquare, TopSquare } from "../Layout/DoubleSquare";
import CircleWithTriangle from "../Layout/CircleWithTriangle";

function Form() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return matches ? (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          inset: 0,
          background:
            "linear-gradient(180.76deg, #CDF3EA 0.21%, #FFFFFF 99.22%)",
        }}
      >
        <Box sx={{ position: "absolute", top: 0, left: 0, height: "25%" }}>
          <TriangleWithCircle />
        </Box>
        <Box sx={{ position: "absolute", top: 0, right: 0, height: "15%" }}>
          <TopSquare />
        </Box>
        <Box sx={{ position: "absolute", bottom: 4, left: 0, height: "25%" }}>
          <BottomSquare />
        </Box>
        <Box sx={{ position: "absolute", bottom: 4, right: 0, height: "20%" }}>
          <CircleWithTriangle />
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(109.56deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 126.32%)",
            backdropFilter: "blur(15px)",
            borderRadius: "30px",
            px: 5,
            border: "1px solid rgba(255, 255, 255, 0.18)",
          }}
        >
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/editProfile"
              element={<EditProfilePage />}
            />
            <Route
              exact
              path="/updatePassword"
              element={<UpdatePassword />}
            />
            <Route exact path="/listProduct" element={<ListProduct />} />
            <Route exact path="/editProduct" element={<EditProduct />} />
            <Route
              exact
              path="/forgotPassword/Email"
              element={<ForgotPasswordEmail />}
            />
            <Route
              exact
              path="/forgotPassword/Password"
              element={<ForgotPasswordPassword />}
            />

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/editProfile" element={<EditProfilePage />} />
        <Route exact path="/updatePassword" element={<UpdatePassword />} />
        <Route exact path="/listProduct" element={<ListProduct />} />
        <Route exact path="/editProduct" element={<EditProduct />} />
        <Route
          exact
          path="/forgotPassword/Email"
          element={<ForgotPasswordEmail />}
        />
        <Route
          exact
          path="/forgotPassword/Password"
          element={<ForgotPasswordPassword />}
        />

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </>
  );
}

export default Form;
