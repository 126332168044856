import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

function UpdatePassword() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [validationObj, setValidationObj] = useState({
    newpass: {
      error: false,
      message: "",
    },
    confirmPassword: {
      error: false,
      message: "",
    },
  });
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const token = searchParams.get("token");

  const putData = async (data) => {
    console.log(data);
    setLoading(true);
    setMessage("");
    setError("");
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/forgot/password`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log(res);
      if (res.message === "Password has been succesfully reset") {
        setLoading(false);
        setMessage(res.message);
        setOpen(true);
        setTimeout(() => {
          navigate("/form/login");
        }, 2000);
      } else {
        setLoading(false);
        setError("Error Updating Password");
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setError("Error Updating Password");
      setOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      newpass: "",
    },
    onSubmit: async (data) => {
      if (checkHandler()) {
        try {
          putData(data);
        } catch (err) {
          console.log(err);
        }
      } else {
        setError(true);
        setOpen(true);
      }
    },
  });

  const checkHandler = () => {
    for (let i in validationObj) {
      if (validationObj[i].error === true || formik.values[i] === "") {
        setMessage("Invalid Entries");
        return false;
      }
    }
    if (formik.values.newpass !== confirmPassword) {
      console.log(formik.values.newpass);
      setMessage("Passwords do not match");
      return false;
    }
    return true;
  };

  const handleShowPassword = () => {
    setShow(!show);
  };

  const handleShowPassword2 = () => {
    setShow2(!show2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    //updating error state
    let error = "";
    let message = "";
    let name = e.target.name;
    let value = e.target.value;

    if (name === "newpass") {
      if (
        !value.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
      ) {
        error = true;
        message = value
          ? "Password should contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character"
          : "Password is required";
        setValidationObj({ ...validationObj, newpass: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, newpass: { error, message } });
      }
    } else if (name === "confirmPassword") {
      if (value !== formik.values.newpass) {
        error = true;
        message = value
          ? "Passwords do not match"
          : "Confirm Password is required";
        setValidationObj({
          ...validationObj,
          confirmPassword: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          confirmPassword: { error, message },
        });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: matches ? "center" : "flex-start",
        justifyContent: "center",
        padding: "1.5rem",
        backgroundColor: matches ? "none" : "#F7F7F7",
        borderRadius: "1rem",
        height: "fit-content",
        width: "auto",
        margin: "auto",
        boxSizing: "border-box",
      }}
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="on"
    >
          <Stack sx={{ 
          alignItems: "flex-start",
          mt: matches ? 2 : "125px",
          width: "100%",}}>
            <Typography sx={{ fontSize: "22px", fontWeight: 500, mb: 2, color: "#39c7a5" }}>
              Update Password
            </Typography>
            
              {/* Password */}
              <TextField
                error={validationObj.newpass.error}
                helperText={
                  validationObj.newpass.error
                    ? validationObj.newpass.message
                    : ""
                }
                required
                id="newpass"
                name="newpass"
                label="Password"
                type={show ? "text" : "password"}
                placeholder="Password"
                onChange={handleChange}
                value={formik.values.newpass}
                sx={{ width: "100%", mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Confirm Password */}
              <TextField
                error={validationObj.confirmPassword.error}
                helperText={
                  validationObj.confirmPassword.error
                    ? validationObj.confirmPassword.message
                    : ""
                }
                required
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type={show2 ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={(e) => {
                  handleChange(e);
                  setConfirmPassword(e.target.value);
                }}
                value={confirmPassword}
                sx={{ width: "100%", mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle cpassword visibility"
                        onClick={handleShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {show2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Submit Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{ width: "100%", color:"#fff" }}
                  disabled={loading}
                >
                  {loading && (
                    <>
                      <CircularProgress
                        thickness={6}
                        color="inherit"
                        size="1.2rem"
                      />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "0.5rem" }}
                      >
                        Updating Password...
                      </Typography>
                    </>
                  )}
                  {!loading && "SUBMIT"}
                </Button>
              </Box>
          </Stack>

      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default UpdatePassword;
