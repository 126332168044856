import React from "react";

function TriangleWithCircle() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50.5 -26L176.507 192.25H-75.5067L50.5 -26Z" fill="#69D3BB" />
      <circle cx="175" cy="189" r="25" fill="#58CBAF" fillOpacity="0.75" />
    </svg>
  );
}

export default TriangleWithCircle;
