import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";

const categories = [
  "Apps and Games",
  "Clothing and Accessories",
  "Education",
  "Electronics",
  "Groceries",
  "Home and Kitchen",
  "Stationery",
  "Sports and Fitness",
  "Others",
];

function EditProduct() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationObj, setValidationObj] = useState({
    productName: {
      error: false,
      message: "",
    },
    productDesc: {
      error: false,
      message: "",
    },
    reason: {
      error: false,
      message: "",
    },
    purchaseDate: {
      error: false,
      message: "",
    },
    productPrice: {
      error: false,
      message: "",
    },
    productCategory: {
      error: false,
      message: "",
    },
  });
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const productData = JSON.parse(localStorage.getItem("product"));
  var token=""

  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        token=data.accessToken;
      } else {
        navigate("/form/login");
      }
    } catch {
      navigate("/form/login");
    }
  };

  const putData = async (values) => {
    setLoading(true);
    setMessage("");
    setError("");
    var formdata = new FormData();
    formdata.append("productName", formik.values.productName);
    formdata.append("productDesc", formik.values.productDesc);
    formdata.append("productPrice", formik.values.productPrice);
    formdata.append("reason", formik.values.reason);
    formdata.append("purchaseDate", formik.values.purchaseDate);
    formdata.append("category", formik.values.category);
    if (values.file !== "") {
      formdata.append("file", values.file, values.file.name);
    }
    let url = `https://${process.env.REACT_APP_BASE_URL}/product/updateProduct?id=${productData.id}`;
    try {
      await gettoken();
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      });
      let data = await response.json();
      if (data.status) {
        setLoading(false);
        setMessage("Product Updated Successfully");
        setOpen(true);
        setTimeout(() => {
          navigate("/mylistings");
        }, 2000);
      } else {
        setLoading(false);
        setError(true);
        setMessage("Unable to update product");
        setOpen(true);
        setTimeout(() => {
          navigate("/mylistings");
        }, 2000);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      setError("Something went wrong");
      setOpen(true);
      setTimeout(() => {
        navigate("/mylistings");
      }, 2000);
    }
  };

  const formik = useFormik({
    initialValues: {
      productName: `${productData.productName}`,
      productDesc: `${productData.productDesc}`,
      reason: `${productData.reason}`,
      purchaseDate: `${moment(productData.purchaseDate).format("YYYY-MM-DD")}`,
      productPrice: `${productData.productPrice}`,
      category: `${productData.category}`,
      file: "",
    },
    onSubmit: async (values) => {
      if (checkHandler()) {
        try {
          putData(values);
        } catch (err) {
          console.log(err);
        }
      } else {
        setError(true);
        setMessage("Invalid Entries");
        setOpen(true);
      }
    },
  });

  const checkHandler = () => {
    for (let i in validationObj) {
      if (validationObj[i].error === true || formik.values[i] === "") {
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    //updating error state
    let error = "";
    let message = "";
    let name = e.target.name;
    let value = e.target.value;

    if (name === "productName") {
      if (value.length < 3) {
        error = true;
        message = !value
          ? "Product Name is required"
          : "Product Name should be atleast 3 characters long";
        setValidationObj({ ...validationObj, productName: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, productName: { error, message } });
      }
    } else if (name === "productDesc") {
      if (value.length < 3) {
        error = true;
        message = !value
          ? "Product Description is required"
          : "Product Description should be atleast 3 characters long";
        setValidationObj({ ...validationObj, productDesc: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, productDesc: { error, message } });
      }
    } else if (name === "reason") {
      if (value.length < 3) {
        error = true;
        message = !value
          ? "Reason is required"
          : "Reason should be atleast 3 characters long";
        setValidationObj({ ...validationObj, reason: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, reason: { error, message } });
      }
    } else if (name === "purchaseDate") {
      const now = moment().format("YYYY-MM-DD");
      if (!value) {
        error = true;
        message = "Purchase Date is required";
        setValidationObj({
          ...validationObj,
          purchaseDate: { error, message },
        });
      } else if (moment(value).isAfter(now)) {
        error = true;
        message = "Purchase Date should be before today";
        setValidationObj({
          ...validationObj,
          purchaseDate: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          purchaseDate: { error, message },
        });
      }
    } else if (name === "productPrice") {
      if (!value) {
        error = true;
        message = "Product Price is required";
        setValidationObj({
          ...validationObj,
          productPrice: { error, message },
        });
      } else if (!value.match(/^[0-9]*$/)) {
        error = true;
        message = "Product Price should contain only numbers";
        setValidationObj({
          ...validationObj,
          productPrice: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          productPrice: { error, message },
        });
      }
    } else if (name === "productCategory") {
      if (!value || value === "default") {
        error = true;
        message = "Product Category is required";
        setValidationObj({
          ...validationObj,
          productCategory: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          productCategory: { error, message },
        });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: matches ? "center" : "flex-start",
        justifyContent: "center",
        padding: "1.5rem",
        backgroundColor: matches ? "none" : "#F7F7F7",
        borderRadius: "1rem",
        height: "fit-content",
        width: matches ? "25em" : "100%",
        margin: "auto",
        boxSizing: "border-box",
      }}
      noValidate
      onSubmit={formik.handleSubmit}
      autoComplete="on"
    >
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          navigate("/mylistings");
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <Stack
        sx={{
          alignItems: matches ? "center" : "flex-start",
          mt: 2,
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 500,
            color: "#39C7A5",
            mb: 2,
          }}
        >
          Edit Your Product
        </Typography>

        {/* Product Name */}
        <TextField
          error={validationObj.productName.error}
          helperText={
            validationObj.productName.error
              ? validationObj.productName.message
              : ""
          }
          required
          id="productName"
          name="productName"
          label="Product Name"
          onChange={handleChange}
          value={formik.values.productName}
          sx={{ width: "100%", mb: 2 }}
        />

        {/* Product Description */}
        <TextField
          error={validationObj.productDesc.error}
          helperText={
            validationObj.productDesc.error
              ? validationObj.productDesc.message
              : ""
          }
          required
          multiline
          rows={2}
          id="productDesc"
          name="productDesc"
          label="Product Description"
          onChange={handleChange}
          value={formik.values.productDesc}
          sx={{ width: "100%", mb: 2 }}
        />

        {/* Reason for Selling */}
        <TextField
          error={validationObj.reason.error}
          helperText={
            validationObj.reason.error ? validationObj.reason.message : ""
          }
          required
          multiline
          rows={2}
          id="reason"
          name="reason"
          label="Reason for Selling"
          onChange={handleChange}
          value={formik.values.reason}
          sx={{ width: "100%", mb: 2 }}
        />

        {/* Product Category */}
        <TextField
          error={validationObj.productCategory.error}
          helperText={
            validationObj.productCategory.error
              ? validationObj.productCategory.message
              : ""
          }
          required
          id="productCategory"
          name="productCategory"
          select
          label="Product Category"
          defaultValue={formik.values.category}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value !== "default") {
              formik.setValues({
                ...formik.values,
                productCategory: e.target.value,
              });
            } else {
              formik.setValues({
                ...formik.values,
                productCategory: "",
              });
            }
          }}
          sx={{ width: "100%", mb: 2 }}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>

        {/* Price and Date */}
        <Stack direction="row" spacing={2}>
          {/* Product Price */}
          <TextField
            error={validationObj.productPrice.error}
            helperText={
              validationObj.productPrice.error
                ? validationObj.productPrice.message
                : ""
            }
            required
            id="productPrice"
            name="productPrice"
            label="Product Price"
            onChange={handleChange}
            value={formik.values.productPrice}
            sx={{ width: "100%", mb: 2 }}
          />

          {/* Date of Purchase */}
          <TextField
            error={validationObj.purchaseDate.error}
            helperText={
              validationObj.purchaseDate.error
                ? validationObj.purchaseDate.message
                : ""
            }
            required
            id="purchaseDate"
            name="purchaseDate"
            label="Date of Purchase"
            type="date"
            onChange={handleChange}
            value={formik.values.purchaseDate}
            sx={{ width: "100%", mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>

        {/* Product Image */}
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: "center", mb: 2, mr: "auto" }}
        >
          <Button variant="outlined" component="label">
            Upload File
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => {
                const file = e.target.files[0];
                const type = file.type;
                if (!type.match(/^image\/[a-zA-Z]*$/)) {
                  alert("Please upload an image file");
                  return;
                }
                formik.setFieldValue("file", file);
              }}
            />
          </Button>
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            {formik.values.file.name}
          </Typography>
          {formik.values.file.name && (
            <IconButton
              onClick={() => {
                formik.setFieldValue("file", "");
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </Stack>

        {/* List Button*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "100%", color: "#fff" }}
            disabled={loading}
          >
            {loading && (
              <>
                <CircularProgress thickness={6} color="inherit" size="1.2rem" />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Updating Item...
                </Typography>
              </>
            )}
            {!loading && "UPDATE ITEM"}
          </Button>
        </Box>
      </Stack>
      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default EditProduct;
