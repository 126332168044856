import React, { useState } from "react";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./form.css";

const blocks = [
  {
    key: "MH",
    blocks: [
      {
        key: "MH_A",
        value: "A Block",
      },
      {
        key: "MH_B",
        value: "B Block",
      },
      {
        key: "MH_BAnnex",
        value: "B-Annex",
      },
      {
        key: "MH_C",
        value: "C Block",
      },
      {
        key: "MH_D",
        value: "D Block",
      },
      {
        key: "MH_DAnnex",
        value: "D-Annex",
      },
      {
        key: "MH_E",
        value: "E Block",
      },
      {
        key: "MH_F",
        value: "F Block",
      },
      {
        key: "MH_G",
        value: "G Block",
      },
      {
        key: "MH_H",
        value: "H Block",
      },
      {
        key: "MH_J",
        value: "J Block",
      },
      {
        key: "MH_K",
        value: "K Block",
      },
      {
        key: "MH_L",
        value: "L Block",
      },
      {
        key: "MH_M",
        value: "M Block",
      },
      {
        key: "MH_N",
        value: "N Block",
      },
      {
        key: "MH_P",
        value: "P Block",
      },
      {
        key: "MH_Q",
        value: "Q Block",
      },
      {
        key: "MH_R",
        value: "R Block",
      },
    ],
  },
  {
    key: "LH",
    blocks: [
      {
        key: "LH_A",
        value: "A Block",
      },
      {
        key: "LH_B",
        value: "B Block",
      },
      {
        key: "LH_C",
        value: "C Block",
      },
      {
        key: "LH_D",
        value: "D Block",
      },
      {
        key: "LH_E",
        value: "E Block",
      },
      {
        key: "LH_F",
        value: "F Block",
      },
      {
        key: "LH_G",
        value: "G Block",
      },
      {
        key: "LH_H",
        value: "H Block",
      },
    ],
  },
];

function Register() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [blocksSelected, setBlocks] = useState([]);
  const [cpassword, setCpassword] = useState("");
  const [validationObj, setValidationObj] = useState({
    firstName: {
      error: false,
      message: "",
    },
    lastName: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    mobileNumber: {
      error: false,
      message: "",
    },
    registrationNumber: {
      error: false,
      message: "",
    },
    hostel: {
      error: false,
      message: "",
    },
    block: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
    cpassword: {
      error: false,
      message: "",
    },
  });
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const format = (formData) => {
    let data = {};
    formData.registrationNumber = formData.registrationNumber.toUpperCase();
    formData.mobileNumber = formData.mobileNumber.replace("-", "");
    formData.mobileNumber = formData.mobileNumber.replace(" ", "");
    for (let key in formData) {
      if (formData[key] !== "") {
        data[key] = formData[key].trim();
      }
    }
    return data;
  };

  const putData = async (formData) => {
    setMessage("");
    setError("");
    setLoading(true);
    const values = format(formData);
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/createUser`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      let data = await response.json();
      localStorage.setItem("data", JSON.stringify(data));
      if (data.status) {
        if (data.emailStatus.status) {
          setLoading(false);
          setMessage("Account created successfully");
          setOpen(true);
          setTimeout(() => {
            navigate("/form/login");
          }, 2000);
        }
      } else {
        setLoading(false);
        setError((data.message.errors[0].message).toUpperCase());
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setError("Something went wrong");
    }
  };

  //FORM HANDLING LIBRARY
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      registrationNumber: "",
      block: "",
      password: "",
    },
    onSubmit: async (values) => {
      if (checkHandler()) {
        try {
          putData(values);
        } catch (error) {
          console.log(error);
        }
      } else {
        setError(true);
        setOpen(true);
      }
    },
  });

  //to reset form
  const resetForm = () => {
    formik.resetForm();
    setBlocks([]);
    for (let i in validationObj) {
      validationObj[i].error = false;
      validationObj[i].message = "";
    }
    setCpassword("");
    setShow(false);
    setShow2(false);
  };

  //to show and hide password
  const handleShowPassword = () => {
    setShow((prev) => !prev);
  };
  const handleShowPassword2 = () => {
    setShow2((prev) => !prev);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //to check for errors
  const checkHandler = () => {
    for (let i in validationObj) {
      if (validationObj[i].error === true || formik.values[i] === "") {
        setMessage("Please fill all the fields correctly");
        return false;
      }
    }
    if (cpassword !== formik.values.password) {
      setMessage("Passwords do not match");
      return false;
    }
    return true;
  };

  //to validate the form
  const handleChange = (e) => {
    formik.handleChange(e);
    //updating error state
    let error = "";
    let message = "";
    let name = e.target.name;
    let value = e.target.value;

    if (name === "firstName") {
      if (!value) {
        error = true;
        message = "Name is required";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else if (value.length < 3) {
        error = true;
        message = "Name should be atleast 3 characters long";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else if (!value.match(/^[a-zA-Z ]*$/)) {
        error = true;
        message = "Name should contain only alphabets";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      }
    } else if (name === "lastName") {
      if (!value) {
        error = true;
        message = "Name is required";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else if (value.length < 3) {
        error = true;
        message = "Name should be atleast 3 characters long";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else if (!value.match(/^[a-zA-Z ]*$/)) {
        error = true;
        message = "Name should contain only alphabets";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      }
    } else if (name === "email") {
      if (!value) {
        error = true;
        message = "Email is required";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}[\s]*$/)) {
        error = true;
        message = "Email is invalid";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@(vitstudent.ac.in)[\s]*$/)) {
        error = true;
        message = "Email should be of VIT Student";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, email: { error, message } });
      }
    } else if (name === "registrationNumber") {
      if (!value) {
        error = true;
        message = "Registration Number is required";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      } else if (!value.match(/^[0-9]{2}[a-zA-Z]{3}[0-9]{4}[\s]*$/)) {
        error = true;
        message = "Registration Number is invalid";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      }
    } else if (name === "hostel") {
      if (value === "default") {
        error = true;
        message = "Hostel is required";
        setValidationObj({ ...validationObj, hostel: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, hostel: { error, message } });
      }
    } else if (name === "block") {
      if (!value || value === "default") {
        error = true;
        message = "Block is required";
        setValidationObj({ ...validationObj, block: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, block: { error, message } });
      }
    } else if (name === "password") {
      if (
        !value.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
      ) {
        error = true;
        message = value
          ? "Password should contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character"
          : "Password is required";
        setValidationObj({ ...validationObj, password: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, password: { error, message } });
      }
    } else if (name === "cpassword") {
      if (!value) {
        error = true;
        message = "Confirm Password is required";
        setValidationObj({
          ...validationObj,
          cpassword: { error, message },
        });
      } else if (value !== formik.values.password) {
        error = true;
        message = "Passwords do not match";
        setValidationObj({
          ...validationObj,
          cpassword: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          cpassword: { error, message },
        });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: matches ? 0 : "1.5rem",
        backgroundColor: matches ? "none" : "#F7F7F7",
        borderRadius: "1rem",
        height: "fit-content",
        width: matches ? "25em" : "100%",
        margin: "auto",
        boxSizing: "border-box",
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      {/* Form Heading */}
      <Box sx={{ mt: 1, mb: matches ? 0 : 4, mr: "auto" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "30px",
            fontWeight: 700,
            color: "#39C7A5",
          }}
        >
          Hello there!
        </Typography>
        <Typography
          sx={{ textAlign: "left", fontSize: "18px", fontWeight: 600 }}
        >
          Create an account to get started
        </Typography>
      </Box>

      {/*First Name */}
      <TextField
        error={validationObj.firstName.error}
        helperText={
          validationObj.firstName.error ? validationObj.firstName.message : ""
        }
        required
        id="firstName"
        name="firstName"
        label="First Name"
        placeholder="First name"
        onChange={handleChange}
        value={formik.values.firstName}
        sx={{ width: "100%", mb: 2 }}
      />

      {/* Last Name  */}
      <TextField
        error={validationObj.lastName.error}
        helperText={
          validationObj.lastName.error ? validationObj.lastName.message : ""
        }
        required
        id="lastName"
        name="lastName"
        label="Last Name"
        placeholder="Last name"
        onChange={handleChange}
        value={formik.values.lastName}
        sx={{ width: "100%", mb: 2 }}
      />

      {/* Email ID */}
      <TextField
        error={validationObj.email.error}
        helperText={
          validationObj.email.error ? validationObj.email.message : ""
        }
        required
        id="email"
        name="email"
        label="Email ID"
        placeholder="Enter your VIT email ID"
        onChange={handleChange}
        value={formik.values.email}
        sx={{ width: "100%", mb: 2 }}
      />

      {/* Mobile Number */}
      <PhoneInput
        country={"in"}
        placeholder="+91 12345-67890"
        value={formik.values.mobileNumber}
        inputProps={{
          id: "mobileNumber",
          name: "mobileNumber",
          onChange: (e) => {
            formik.handleChange(e);
            if (!e.target.value || e.target.value.length < 15) {
              setValidationObj({
                ...validationObj,
                mobileNumber: {
                  error: true,
                  message: "Invalid Mobile Number",
                },
              });
            } else {
              setValidationObj({
                ...validationObj,
                mobileNumber: {
                  error: false,
                  message: "",
                },
              });
            }
          },
          required: true,
        }}
        specialLabel="Mobile Number"
        containerStyle={{ padding: "0" }}
        inputStyle={{
          width: "100%",
          backgroundColor: "transparent",
          outline: "none",
          "&:focus": { borderColor: "none", boxShadow: "0 0 0 1px #39c7a5" },
          borderColor: validationObj.mobileNumber.error
            ? "red"
            : formik.values.mobileNumber
            ? "#39c7a5"
            : "none",
          boxShadow: validationObj.mobileNumber.error
            ? "0 0 0 1px red"
            : formik.values.mobileNumber
            ? "0 0 0 1px #39c7a5"
            : "none",
        }}
        buttonStyle={{ background: "none" }}
        localization="in"
      />
      {validationObj.mobileNumber.error && (
        <Typography
          variant="body2"
          color="error"
          sx={{
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
            textAlign: "left",
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: "0",
            marginLeft: "14px",
            alignSelf: "start",
          }}
        >
          {validationObj.mobileNumber.message}
        </Typography>
      )}

      {/* Registration Number */}
      <TextField
        error={validationObj.registrationNumber.error}
        helperText={
          validationObj.registrationNumber.error
            ? validationObj.registrationNumber.message
            : ""
        }
        required
        id="registrationNumber"
        name="registrationNumber"
        label="Registration Number"
        placeholder="Enter your registration number"
        onChange={handleChange}
        value={formik.values.registrationNumber}
        sx={{ width: "100%", mb: 2, mt: 2 }}
      />

      {/* Password */}
      <TextField
        error={validationObj.password.error}
        helperText={
          validationObj.password.error ? validationObj.password.message : ""
        }
        required
        id="password"
        name="password"
        label="Password"
        type={show ? "text" : "password"}
        placeholder="Password"
        onChange={handleChange}
        value={formik.values.password}
        sx={{ width: "100%", mb: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Confirm Password */}
      <TextField
        error={validationObj.cpassword.error}
        helperText={
          validationObj.cpassword.error ? validationObj.cpassword.message : ""
        }
        required
        id="cpassword"
        name="cpassword"
        label="Confirm Password"
        type={show2 ? "text" : "password"}
        placeholder="Confirm Password"
        onChange={(e) => {
          handleChange(e);
          setCpassword(e.target.value);
        }}
        value={cpassword}
        sx={{ width: "100%", mb: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle cpassword visibility"
                onClick={handleShowPassword2}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {show2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Block and Hostel */}
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        {/* Hostel */}
        <TextField
          error={validationObj.hostel.error}
          helperText={
            validationObj.hostel.error ? validationObj.hostel.message : ""
          }
          required
          id="hostel"
          name="hostel"
          select
          label="Hostel"
          defaultValue="default"
          sx={{ width: "100%", mb: 2 }}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value !== "default") {
              setBlocks(
                blocks.find((block) => block.key === e.target.value).blocks
              );
            } else {
              setBlocks([]);
            }
          }}
        >
          <MenuItem key="default" value="default">
            Select Hostel
          </MenuItem>
          <MenuItem key="MH" value="MH">
            Men's Hostel
          </MenuItem>
          <MenuItem key="LH" value="LH">
            Ladies' Hostel
          </MenuItem>
        </TextField>

        {/* Block */}
        <TextField
          error={validationObj.block.error}
          helperText={
            validationObj.block.error ? validationObj.block.message : ""
          }
          required
          id="block"
          name="block"
          select
          label="Block"
          defaultValue="default"
          sx={{ width: "100%", mb: 2 }}
          onChange={(e) => {
            handleChange(e);
            if (e.target.value !== "default") {
              formik.setValues({ ...formik.values, block: e.target.value });
            } else {
              formik.setValues({ ...formik.values, block: "" });
            }
          }}
        >
          <MenuItem key="default" value="default">
            Select Block
          </MenuItem>
          {blocksSelected.map((block) => (
            <MenuItem key={block.key} value={block.key}>
              {block.value}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {/* Submit and reset buttons */}
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          disabled={loading}
          sx={{ color: "#fff" }}
        >
          {loading && (
            <>
              <CircularProgress thickness={6} color="inherit" size="1.2rem" />
              <Typography variant="subtitle2" style={{ marginLeft: "0.5rem" }}>
                Creating Account...
              </Typography>
            </>
          )}
          {!loading && "SUBMIT"}
        </Button>
        <Button
          type="reset"
          variant="contained"
          size="medium"
          onClick={resetForm}
          sx={{ color: "#fff" }}
        >
          RESET
        </Button>
      </Stack>

      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}

      {/* Log in link */}
      <Link
        href="./login"
        variant="body2"
        sx={{
          color: "#000",
          fontWeight: 500,
          fontSize: "14px",
          textDecoration: "none",
        }}
      >
        Existing User? Log In
      </Link>
    </Box>
  );
}

export default Register;
