import * as React from "react";
import { useState } from "react";
// import { styled, alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ReorderIcon from "@mui/icons-material/Reorder";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import {
  Drawer,
  Grid,
  useMediaQuery,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Avatar, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
// import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import logo from "./logo.svg"

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
//   flexGrow: 1,
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     // [theme.breakpoints.up("md")]: {
//     //   width: "20ch",
//     // },
//   },
// }));

function stringAvatar(firstName, lastName) {
  return {
    sx: {
      bgcolor: "#ffffff",
    },
    children: `${firstName[0]}${lastName[0]}`,
  };
}

// const categories = [
//   "Apps and Games",
//   "Clothing and Accessories",
//   "Education",
//   "Electronics",
//   "Groceries",
//   "Home and Kitchen",
//   "Stationery",
//   "Sports and Fitness",
//   "Others",
// ];

function Navbar() {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();
  var user = { firstName: "", lastName: "" };
  if (localStorage.getItem("user") === null) {
    navigate("/form/login");
  } else {
    user = JSON.parse(localStorage.getItem("user"))[0];
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  var token = "";
  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        token = data.accessToken;
      } else {
        navigate("/form/login");
      }
    } catch {
      navigate("/form/login");
    }
  };

  const logout = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/logout`;
    try {
      await gettoken();
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      if (data.status) {
        setLoggingOut(false);
        setMessage("Logged Out Successfully");
        setOpen(true);
        setTimeout(() => {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user");
          localStorage.removeItem("product");
          navigate("/form/login");
        }, 2000);
      } else {
        setLoggingOut(false);
        setMessage("Error Logging Out");
        setOpen(true);
      }
    } catch (err) {
      setLoggingOut(false);
      setError("Error Logging Out");
      setOpen(true);
    }
  };

  const toggleProfileMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (mobileMoreAnchorEl) setMobileMoreAnchorEl(false);

    setAnchorEl(open);
  };

  const toggleMobileMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileMoreAnchorEl(open);
  };

  // const options = categories.map((option) => {
  //   const firstLetter = option[0].toUpperCase();
  //   return {
  //     firstLetter,
  //     ...option,
  //   };
  // });

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <>
      <Box
        spacing={2}
        sx={{
          width: matches ? 400 : "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        role="presentation"
      >
        <Grid
          item
          sx={{
            bgcolor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Avatar
            // {...stringAvatar(user.firstName, user.lastName)}
            sx={{ height: 50, width: 50, bgcolor: "#808080" }}
          />
          <Typography variant="h6">
            {`${user.firstName}${" "}${user.lastName}`}
          </Typography>
        </Grid>
      </Box>
      <Box
        spacing={2}
        sx={{ width: matches ? 400 : "100vw" }}
        role="presentation"
      >
        <Grid
          item
          sx={{
            bgcolor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: 2,
            paddingLeft: 2,
            width: "75%",
            margin: "auto",
          }}
        >
          <Typography>{`Email: ${user.email}`}</Typography>
          <Typography>{`Reg No: ${user.registrationNumber}`}</Typography>
          <Typography>{`Block: ${user.block}`}</Typography>
        </Grid>
      </Box>
      <Box
        spacing={2}
        sx={{ width: matches ? 400 : "100vw" }}
        role="presentation"
      >
        <Grid
          item
          sx={{
            bgcolor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button
            href="./form/editProfile"
            variant="outlined"
            color="primary"
            sx={{ width: "65%" }}
            onClick={toggleProfileMenu(false)}
            onKeyDown={toggleProfileMenu(false)}
          >
            Edit Profile
          </Button>
          <Button
            href="./form/updatePassword"
            variant="outlined"
            color="primary"
            sx={{ width: "65%" }}
            onClick={toggleProfileMenu(false)}
            onKeyDown={toggleProfileMenu(false)}
          >
            Change Password
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ width: "65%", display: matches ? "inline-flex" : "none" }}
            disabled={loggingOut}
            onClick={() => {
              setLoggingOut(true);
              logout();
              toggleProfileMenu(false);
            }}
          >
            {loggingOut && (
              <>
                <CircularProgress thickness={6} color="inherit" size="1.2rem" />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Logging Out...
                </Typography>
              </>
            )}
            {!loggingOut && "Logout"}
          </Button>
        </Grid>
      </Box>
      {!matches && (
        <Box sx={{ position: "absolute", right: 20, top: 20 }}>
          <CloseIcon fontSize="large" onClick={toggleProfileMenu(false)} />
        </Box>
      )}
    </>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={mobileMoreAnchorEl}
      onClose={toggleMobileMenu(false)}
    >
      <Link href="./MyListings" underline="none" color="#000">
        <MenuItem onClick={toggleMobileMenu(false)}>
          <IconButton size="large" color="inherit">
            <AddShoppingCartIcon />
          </IconButton>
          <p>My Listings</p>
        </MenuItem>
      </Link>
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <ChatIcon />
        </IconButton>
        <p>Chats</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <FavoriteIcon />
        </IconButton>
        <p>Wishlist</p>
      </MenuItem>
      <Divider /> */}
      <MenuItem onClick={toggleProfileMenu(true)}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setLoggingOut(true);
          logout();
          toggleProfileMenu(false);
        }}
      >
        {loggingOut && (
          <>
            <CircularProgress thickness={6} color="inherit" size="1.2rem" />
            <Typography variant="subtitle2" style={{ marginLeft: "0.5rem" }}>
              Logging Out...
            </Typography>
          </>
        )}
        {!loggingOut && (
          <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
            <p>Logout</p>
          </>
        )}
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {message ? message : error}
        </Alert>
      </Snackbar>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            href="./Home"
          >
            <img src={logo} height={36} alt="Logo" />
          </IconButton>
          <Link
            href="./Home"
            underline="none"
            variant="h6"
            noWrap
            sx={{ display: { xs: "none", sm: "block" }, color: "#fff" }}
          >
            Thrift
          </Link>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Seach…"
              inputProps={{ "aria-label": "search" }}
              sx={{ width: "100%" }}
            />
          </Search> */}
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 0, ml: "auto" }}>
            <Link
              padding="0.5rem"
              alignSelf="center"
              href="./MyListings"
              underline="none"
              variant="button"
              fontSize="1rem"
              noWrap
              sx={{ display: { xs: "none", sm: "block" }, color: "#fff" }}
            >
              My Listings
            </Link>
            {/* <Link
              padding="0.5rem"
              alignSelf="center"
              color="#000"
              href="./Chats"
              underline="none"
              variant="button"
              fontSize="1rem"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Chats
            </Link> */}
            {/* <Link
              padding="0.5rem"
              alignSelf="center"
              color="#000"
              href="./Wishlist"
              underline="none"
              variant="button"
              fontSize="1rem"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Wishlist
            </Link> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={toggleProfileMenu(true)}
            >
              <Avatar
                {...stringAvatar(user.firstName, user.lastName)}
                sx={{ color: "#39c7a5", backgroundColor: "#fff" }}
              />
              {/* <AccountCircle /> */}
            </IconButton>
            <Drawer
              PaperProps={{ sx: { justifyContent: "space-evenly" } }}
              anchor="right"
              open={anchorEl}
              onClose={toggleProfileMenu(false)}
            >
              {renderMenu}
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 0, ml: "auto" }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={toggleMobileMenu(true)}
              color="inherit"
            >
              <ReorderIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}

export default Navbar;
