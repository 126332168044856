import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

const blocks = [
  {
    key: "MH",
    blocks: [
      {
        key: "MH_A",
        value: "A Block",
      },
      {
        key: "MH_B",
        value: "B Block",
      },
      {
        key: "MH_BAnnex",
        value: "B-Annex",
      },
      {
        key: "MH_C",
        value: "C Block",
      },
      {
        key: "MH_D",
        value: "D Block",
      },
      {
        key: "MH_DAnnex",
        value: "D-Annex",
      },
      {
        key: "MH_E",
        value: "E Block",
      },
      {
        key: "MH_F",
        value: "F Block",
      },
      {
        key: "MH_G",
        value: "G Block",
      },
      {
        key: "MH_H",
        value: "H Block",
      },
      {
        key: "MH_J",
        value: "J Block",
      },
      {
        key: "MH_K",
        value: "K Block",
      },
      {
        key: "MH_L",
        value: "L Block",
      },
      {
        key: "MH_M",
        value: "M Block",
      },
      {
        key: "MH_N",
        value: "N Block",
      },
      {
        key: "MH_P",
        value: "P Block",
      },
      {
        key: "MH_Q",
        value: "Q Block",
      },
      {
        key: "MH_R",
        value: "R Block",
      },
    ],
  },
  {
    key: "LH",
    blocks: [
      {
        key: "LH_A",
        value: "A Block",
      },
      {
        key: "LH_B",
        value: "B Block",
      },
      {
        key: "LH_C",
        value: "C Block",
      },
      {
        key: "LH_D",
        value: "D Block",
      },
      {
        key: "LH_E",
        value: "E Block",
      },
      {
        key: "LH_F",
        value: "F Block",
      },
      {
        key: "LH_G",
        value: "G Block",
      },
      {
        key: "LH_H",
        value: "H Block",
      },
    ],
  },
];

function stringToColor(string) {
  // let hash = 0;
  // let i;

  // /* eslint-disable no-bitwise */
  // for (i = 0; i < string.length; i += 1) {
  //   hash = string.charCodeAt(i) + ((hash << 5) - hash);
  // }

  // let color = "#";

  // for (i = 0; i < 3; i += 1) {
  //   const value = (hash >> (i * 8)) & 0xff;
  //   color += `00${value.toString(16)}`.slice(-2);
  // }
  // /* eslint-enable no-bitwise */

  return "#808080";
}

function stringAvatar(firstName, lastName) {
  return {
    sx: {
      bgcolor: stringToColor(`${firstName}${lastName}`),
    },
    children: `${firstName[0]}${lastName[0]}`,
  };
}

function EditProfilePage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blocksSelected, setBlocks] = useState([]);
  const [validationObj, setValidationObj] = useState({
    firstName: {
      error: false,
      message: "",
    },
    lastName: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    mobileNumber: {
      error: false,
      message: "",
    },
    registrationNumber: {
      error: false,
      message: "",
    },
    hostel: {
      error: false,
      message: "",
    },
    block: {
      error: false,
      message: "",
    },
  });
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("user"))[0];
  useEffect(() => {
    setBlocks(
      blocks.find((block) => block.key === user.block.slice(0, 2)).blocks
    );
    //eslint-disable-next-line
  }, []);

  var token="";
  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        token = data.accessToken;
      } else {
        navigate("/form/login");
      }
    } catch {
      navigate("/form/login");
    }
  };

  const putData = async (values) => {
    setLoading(true);
    setMessage("");
    setError("");
    values.mobileNumber = values.mobileNumber.replace("-", "");
    values.mobileNumber = values.mobileNumber.replace(" ", "");
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/updateUser`;
    try {
      await gettoken();
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      let data = await response.json();
      if (data.status) {
        localStorage.setItem("user", JSON.stringify([values]));
        setLoading(false);  
        setMessage(data.message);
        setOpen(true);
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } else {
        setLoading(false);
        setError(true);
        setMessage("User not updated");
        setOpen(true);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      setError("Something went wrong");
      setOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: `${user.firstName}`,
      lastName: `${user.lastName}`,
      email: `${user.email}`,
      mobileNumber: `${user.mobileNumber}`,
      registrationNumber: `${user.registrationNumber}`,
      block: `${user.block}`,
    },
    onSubmit: async (values) => {
      if (checkHandler()) {
        try {
          putData(values);
        } catch (err) {
          console.log(err);
        }
      } else {
        setError(true);
        setMessage("Invalid Entries");
        setOpen(true);
      }
    },
  });

  const checkHandler = () => {
    for (let i in validationObj) {
      if (validationObj[i].error === true || formik.values[i] === "") {
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    //updating error state
    let error = "";
    let message = "";
    let name = e.target.name;
    let value = e.target.value;

    if (name === "firstName") {
      if (!value) {
        error = true;
        message = "Name is required";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else if (value.length < 3) {
        error = true;
        message = "Name should be atleast 3 characters long";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else if (!value.match(/^[a-zA-Z ]*$/)) {
        error = true;
        message = "Name should contain only alphabets";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, firstName: { error, message } });
      }
    } else if (name === "lastName") {
      if (!value) {
        error = true;
        message = "Name is required";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else if (value.length < 3) {
        error = true;
        message = "Name should be atleast 3 characters long";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else if (!value.match(/^[a-zA-Z ]*$/)) {
        error = true;
        message = "Name should contain only alphabets";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, lastName: { error, message } });
      }
    } else if (name === "email") {
      if (!value) {
        error = true;
        message = "Email is required";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        error = true;
        message = "Email is invalid";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else if (!value.match(/^[\w-.]+@(vitstudent.ac.in)$/)) {
        error = true;
        message = "Email should be of VIT Student";
        setValidationObj({ ...validationObj, email: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, email: { error, message } });
      }
    } else if (name === "registrationNumber") {
      if (!value) {
        error = true;
        message = "Registration Number is required";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      } else if (!value.match(/^[0-9]{2}[a-zA-Z]{3}[0-9]{4}$/)) {
        error = true;
        message = "Registration Number is invalid";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      } else {
        error = false;
        message = "";
        setValidationObj({
          ...validationObj,
          registrationNumber: { error, message },
        });
      }
    } else if (name === "hostel") {
      if (value === "default") {
        error = true;
        message = "Hostel is required";
        setValidationObj({ ...validationObj, hostel: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, hostel: { error, message } });
      }
    } else if (name === "block") {
      if (!value || value === "default") {
        error = true;
        message = "Block is required";
        setValidationObj({ ...validationObj, block: { error, message } });
      } else {
        error = false;
        message = "";
        setValidationObj({ ...validationObj, block: { error, message } });
      }
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: matches ? 0 : "1.5rem",
        backgroundColor: matches ? "none" : "#F7F7F7",
        borderRadius: "1rem",
        height: "fit-content",
        width: matches ? "25em" : "100%",
        margin: "auto",
        boxSizing: "border-box",
      }}
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          navigate("/home");
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Stack sx={{ alignItems: "center" }}>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", my: 2, color: "#39C7A" }}
        >
          Edit Your Profile
        </Typography>
        <Avatar
          {...stringAvatar(user.firstName, user.lastName)}
          sx={{ height: 60, width: 60, color: "#000", mb: 3 }}
        />

        {/* Name */}
        <Stack
          direction={matches ? "row" : "column"}
          spacing={matches ? 2 : 0}
          sx={{ width: "100%" }}
        >
          {/* First Name */}
          <TextField
            error={validationObj.firstName.error}
            helperText={
              validationObj.firstName.error
                ? validationObj.firstName.message
                : ""
            }
            required
            id="firstName"
            name="firstName"
            label="First Name"
            onChange={handleChange}
            value={formik.values.firstName}
            sx={{ width: "100%", mb: 2 }}
          />

          {/* Last Name */}
          <TextField
            error={validationObj.lastName.error}
            helperText={
              validationObj.lastName.error ? validationObj.lastName.message : ""
            }
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            onChange={handleChange}
            value={formik.values.lastName}
            sx={{ width: "100%", mb: 2 }}
          />
        </Stack>

        <Stack
          direction={matches ? "row" : "column"}
          spacing={matches ? 2 : 0}
          sx={{ width: "100%" }}
        >
          {/* Mobile Number */}
          <Stack direction="column" sx={{ width: "100%" }}>
            <PhoneInput
              country={"in"}
              placeholder="+91 12345-67890"
              value={formik.values.mobileNumber}
              defaultValue={user.mobileNumber}
              inputProps={{
                id: "mobileNumber",
                name: "mobileNumber",
                onChange: (e) => {
                  formik.handleChange(e);
                  if (!e.target.value || e.target.value.length < 15) {
                    setValidationObj({
                      ...validationObj,
                      mobileNumber: {
                        error: true,
                        message: "Invalid Mobile Number",
                      },
                    });
                  } else {
                    setValidationObj({
                      ...validationObj,
                      mobileNumber: {
                        error: false,
                        message: "",
                      },
                    });
                  }
                },
                required: true,
              }}
              specialLabel="Mobile Number"
              containerStyle={{ padding: "0" }}
              inputStyle={{
                width: "100%",
                backgroundColor: "transparent",
                outline: "none !important",
                "&:focus": { outline: "none !important" },
                borderColor: validationObj.mobileNumber.error ? "red" : "",
              }}
              buttonStyle={{ background: "none" }}
              localization="in"
            />
            {validationObj.mobileNumber.error && (
              <Typography
                variant="body2"
                color="error"
                sx={{
                  fontSize: "0.75rem",
                  lineHeight: 1.66,
                  letterSpacing: "0.03333em",
                  textAlign: "left",
                  marginTop: "3px",
                  marginRight: "14px",
                  marginBottom: "0",
                  marginLeft: "14px",
                }}
              >
                {validationObj.mobileNumber.message}
              </Typography>
            )}
          </Stack>

          {/* Registration Number */}
          <TextField
            error={validationObj.registrationNumber.error}
            helperText={
              validationObj.registrationNumber.error
                ? validationObj.registrationNumber.message
                : ""
            }
            required
            id="registrationNumber"
            name="registrationNumber"
            label="Registration Number"
            placeholder="Enter your registration number"
            value={formik.values.registrationNumber}
            InputProps={{ readOnly: true }}
            sx={{ width: "100%", mb: 2, mt: matches ? 0 : 2 }}
          />
        </Stack>

        {/* Email */}
        <TextField
          error={validationObj.email.error}
          helperText={
            validationObj.email.error ? validationObj.email.message : ""
          }
          required
          id="email"
          name="email"
          label="Email ID"
          placeholder="Enter your email ID"
          value={formik.values.email}
          InputProps={{ readOnly: true }}
          sx={{ width: "100%", mb: 2, mt: matches ? 2 : 0 }}
        />

        {/* Block and Hostel */}
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          {/* Hostel */}
          <TextField
            error={validationObj.hostel.error}
            helperText={
              validationObj.hostel.error ? validationObj.hostel.message : ""
            }
            required
            id="hostel"
            name="hostel"
            select
            label="Hostel"
            defaultValue={user.block.slice(0, 2)}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value !== "default") {
                setBlocks(
                  blocks.find((block) => block.key === e.target.value).blocks
                );
              } else {
                setBlocks([]);
              }
            }}
          >
            <MenuItem key="default" value="default">
              Select Hostel
            </MenuItem>
            <MenuItem key="MH" value="MH">
              Men's Hostel
            </MenuItem>
            <MenuItem key="LH" value="LH">
              Ladies' Hostel
            </MenuItem>
          </TextField>

          {/* Block */}
          <TextField
            error={validationObj.block.error}
            helperText={
              validationObj.block.error ? validationObj.block.message : ""
            }
            required
            id="block"
            name="block"
            select
            label="Block"
            defaultValue={formik.values.block}
            sx={{ width: "100%", mb: 2 }}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value !== "default") {
                formik.setValues({
                  ...formik.values,
                  block: e.target.value,
                });
              } else {
                formik.setValues({ ...formik.values, block: "" });
              }
            }}
          >
            <MenuItem key="default" value="default">
              Select Block
            </MenuItem>
            {blocksSelected.map((block) => (
              <MenuItem key={block.key} value={block.key}>
                {block.value}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        {/* Submit */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ width: "100%", color: "#fff" }}
            disabled={loading}
          >
            {loading && (
              <>
                <CircularProgress thickness={6} color="inherit" size="1.2rem" />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Updating Profile...
                </Typography>
              </>
            )}
            {!loading && "SUBMIT"}
          </Button>
        </Box>
      </Stack>

      {/* Snackbar */}
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={error ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {message ? message : error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default EditProfilePage;
