import React, { useEffect, useState } from "react";
import Navbar from "./Layout/Navbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActions,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SortIcon from "@mui/icons-material/Sort";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
//   flexGrow: 1,
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     // [theme.breakpoints.up("md")]: {
//     //   width: "20ch",
//     // },
//   },
// }));

function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loader, setLoader] = useState(false);
  const [productLoading, setProductLoading] = useState(true);
  const [noProducts, setNoProducts] = useState(false);
  const [viewFilters, setViewFilters] = useState(false);
  const [viewSort, setViewSort] = useState(false);
  const [inBlock, setInBlock] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("priceLowToHigh");
  const [categories, setCategories] = useState([
    { name: "Apps and Games", checked: false },
    { name: "Clothing and Accessories", checked: false },
    { name: "Education", checked: false },
    { name: "Electronics", checked: false },
    { name: "Groceries", checked: false },
    { name: "Home and Kitchen", checked: false },
    { name: "Stationery", checked: false },
    { name: "Sports and Fitness", checked: false },
    { name: "Others", checked: false },
  ]);
  const navigate = useNavigate();
  // var token = "";

  const gettoken = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/user/token`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });
      let data = await response.json();
      if (data.accessToken) {
        // token = data.accessToken;
        return true;
      } else {
        navigate("/form/login");
      }
    } catch {
      navigate("/form/login");
    }
  };

  const getProducts = async () => {
    let url = `https://${process.env.REACT_APP_BASE_URL}/product/getProduct`;
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      setProductLoading(true);
      if (data.status) {
        let temp = data.data;
        // temp = temp.filter(
        //   (item) =>
        //     item.User.email !==
        //     JSON.parse(localStorage.getItem("user"))[0].email
        // );
        setProducts(temp);
        temp.sort((a, b) => a.productPrice - b.productPrice);
        setFiltered(temp);
        return true;
      } else {
        setProducts([]);
        setFiltered([]);
        setNoProducts(true);
        console.log("No products found");
        return true;
      }
    } catch (err) {
      setProducts([]);
      setFiltered([]);
      return false;
    }
  };

  useEffect(() => {
    const x = gettoken();
    const y = getProducts();
    if (x && y) {
      setLoader(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((filtered.length === 0 && noProducts) || filtered.length !== 0) {
      setProductLoading(false);
    }
  }, [filtered, noProducts]);

  const toggleFilterMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setViewFilters(open);
  };

  const toggleSortMenu = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setViewSort(open);
  };

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setInBlock(true);
    } else {
      setInBlock(false);
    }

    if (!matches) {
      setTimeout(() => {
        setViewSort(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (inBlock) {
      let temp = localStorage.getItem("user");
      temp = JSON.parse(temp)[0];
      let userBlock = temp.block;
      setFiltered(
        filtered.filter((product) => product.User.block === userBlock)
      );
    } else {
      let temp = [...categories];
      let flag = false;
      for (let i in temp) {
        if (!temp[i].checked) {
          flag = true;
        } else {
          flag = false;
          break;
        }
      }
      if (flag) {
        setFiltered(products);
      } else {
        let tempProducts = [...products];
        tempProducts = tempProducts.filter((product) => {
          let flag = false;
          temp.forEach((category) => {
            if (category.checked && product.category === category.name) {
              flag = true;
            }
          });
          return flag;
        });
        setFiltered(tempProducts);
      }
    }
    // eslint-disable-next-line
  }, [inBlock]);

  const handleChange = (e) => {
    let temp = [...categories];
    temp.forEach((category) => {
      if (category.name === e.target.name) {
        category.checked = e.target.checked;
      }
    });
    setCategories(temp);
  };

  const handleSort = (e) => {
    let temp = [...filtered];
    if (e.target.value === "priceLowToHigh") {
      temp.sort((a, b) => a.productPrice - b.productPrice);
    } else if (e.target.value === "priceHighToLow") {
      temp.sort((a, b) => b.productPrice - a.productPrice);
    } else if (e.target.value === "dateHighToLow") {
      temp.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    } else if (e.target.value === "dateLowToHigh") {
      temp.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    }
    setFiltered(temp);

    if (!matches) {
      setSelectedRadio(e.target.value);
      setTimeout(() => {
        setViewSort(false);
      }, 300);
    }
  };

  const handleFilters = () => {
    let temp = [...categories];
    let flag = false;
    for (let i in temp) {
      if (!temp[i].checked) {
        flag = true;
      } else {
        flag = false;
        break;
      }
    }
    console.log(temp);
    console.log(products);
    // setCategories(temp);

    if (!matches) setViewFilters(false);

    if (flag) {
      if (inBlock) {
        let temp = localStorage.getItem("user");
        temp = JSON.parse(temp)[0];
        let userBlock = temp.block;
        setFiltered(
          products.filter((product) => product.User.block === userBlock)
        );
      } else {
        setFiltered(products);
      }
      return;
    }

    let tempProducts = [...products];
    tempProducts = tempProducts.filter((product) => {
      let flag = false;
      temp.forEach((category) => {
        if (category.checked && product.category === category.name) {
          flag = true;
        }
      });
      return flag;
    });

    if (inBlock) {
      let temp = localStorage.getItem("user");
      temp = JSON.parse(temp)[0];
      let userBlock = temp.block;
      setFiltered(
        tempProducts.filter((product) => product.User.block === userBlock)
      );
    } else {
      setFiltered(tempProducts);
    }

    setViewFilters(false);
  };

  const filterMenu = (
    <>
      <Stack
        display="flex"
        direction="column"
        spacing={4}
        sx={{ minWidth: "20%", pt: 2, px: 8, alignItems: "flex-start" }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mx: "auto", mt: 2 }}
        >
          Filters
        </Typography>
        <FormControl component="form">
          <FormGroup>
            {categories.map((category) => (
              <FormControlLabel
                control={
                  <Checkbox
                    key={category.name}
                    onChange={handleChange}
                    name={category.name}
                    checked={category.checked}
                  />
                }
                label={category.name}
              />
            ))}
          </FormGroup>
          <Button
            variant="contained"
            onClick={() => {
              handleFilters();
            }}
            sx={{ mx: 2, color: "#fff", mt: 4 }}
          >
            Apply
          </Button>
        </FormControl>
      </Stack>
      {!matches && (
        <Box sx={{ position: "absolute", right: 20, top: 75 }}>
          <CloseIcon fontSize="large" onClick={toggleFilterMenu(false)} />
        </Box>
      )}
    </>
  );

  const sortMenu = (
    <>
      <Stack
        display="flex"
        direction="column"
        spacing={4}
        sx={{ minWidth: "20%", pt: 2, px: 2, alignItems: "flex-start" }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={inBlock} />}
            label="In my block"
            onChange={handleSwitch}
          />
        </FormGroup>
        <FormControl>
          <FormLabel
            component="legend"
            sx={{
              fontSize: "19px",
              fontWeight: 500,
              color: "#000",
              mr: 1,
              display: "inline-flex",
            }}
          >
            Sort by
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedRadio}
            name="radio-buttons-group"
            onChange={handleSort}
          >
            <FormControlLabel
              key="priceLowToHigh"
              value="priceLowToHigh"
              control={<Radio />}
              label="Price: Low To High"
            />
            <FormControlLabel
              key="priceHighToLow"
              value="priceHighToLow"
              control={<Radio />}
              label="Price: High To Low"
            />
            <FormControlLabel
              key="dateLowToHigh"
              value="dateLowToHigh"
              control={<Radio />}
              label="Date: Old to New"
            />
            <FormControlLabel
              key="dateHighToLow"
              value="dateHighToLow"
              control={<Radio />}
              label="Date: New to Old"
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      {!matches && (
        <Box sx={{ position: "absolute", right: 20, top: 75 }}>
          <CloseIcon fontSize="large" onClick={toggleSortMenu(false)} />
        </Box>
      )}
    </>
  );

  return (
    <>
      {loader && (
        <>
          <Navbar />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Seach…"
              inputProps={{ "aria-label": "search" }}
              sx={{ width: "100%" }}
            />
          </Search> */}
          <Stack direction={matches ? "row" : "column"}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                pt: 2,
                pr: matches ? 2 : 0,
                mx: matches ? 8 : 0,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  mx: 1,
                  mb: 4,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textAlign: matches ? "left" : "center",
                    fontWeight: 400,
                    fontSize: "32px",
                  }}
                >
                  Catalogue
                </Typography>

                {productLoading ? (
                  <></>
                ) : (
                  <>
                    <IconButton
                      onClick={toggleFilterMenu(true)}
                      sx={{
                        px: 2,
                        display: "inline-flex",
                        ml: matches ? 0 : "auto",
                      }}
                    >
                      <FilterAltIcon />
                    </IconButton>
                  </>
                )}
                <Drawer
                  PaperProps={{
                    sx: { justifyContent: "flex-start", pt: "65px" },
                  }}
                  variant="temporary"
                  anchor="left"
                  open={viewFilters}
                  onClose={toggleFilterMenu(false)}
                  sx={{ zIndex: 1 }}
                >
                  {filterMenu}
                </Drawer>

                {productLoading ? (
                  <Skeleton animation="wave" width={50} />
                ) : (
                  <>
                    <FormGroup
                      sx={{
                        ml: "auto",
                        mr: 1,
                        display: matches ? "inline-flex" : "none",
                      }}
                    >
                      <FormControlLabel
                        control={<Switch />}
                        label="In my block"
                        onChange={handleSwitch}
                      />
                    </FormGroup>

                    <TextField
                      id="sortby"
                      select
                      defaultValue="priceLowToHigh"
                      sx={{ display: matches ? "inline-flex" : "none" }}
                      variant="standard"
                      onChange={handleSort}
                    >
                      <MenuItem value="priceLowToHigh" key="priceLowToHigh">
                        Price: Low to High
                      </MenuItem>
                      <MenuItem value="priceHighToLow" key="proceHighToLow">
                        Price: High to Low
                      </MenuItem>
                      <MenuItem value="dateLowToHigh" key="dateLowToHigh">
                        Date: Old to New
                      </MenuItem>
                      <MenuItem value="dateHighToLow" key="dateHighToLow">
                        Date: New to Old
                      </MenuItem>
                    </TextField>
                  </>
                )}

                {productLoading ? (
                  <></>
                ) : (
                  <IconButton
                    onClick={toggleSortMenu(true)}
                    sx={{ px: 2, display: matches ? "none" : "inline-flex" }}
                  >
                    <SortIcon />
                  </IconButton>
                )}
                <Drawer
                  PaperProps={{
                    sx: { justifyContent: "flex-start", pt: "110px" },
                  }}
                  variant="temporary"
                  anchor="right"
                  open={viewSort}
                  onClose={toggleSortMenu(false)}
                  sx={{ zIndex: 1 }}
                >
                  {sortMenu}
                </Drawer>
              </Stack>
              {productLoading ? (
                <Grid container columns={{ xs: 2, sm: 3, md: 4 }}>
                  <Grid item xs={1} key={1}>
                    <Box sx={{ px: 1, m: 1 }}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={118}
                      />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" width="60%" />
                    </Box>
                  </Grid>
                  <Grid item xs={1} key={2}>
                    <Box sx={{ px: 1, m: 1 }}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={118}
                      />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" width="60%" />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    key={3}
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <Box sx={{ px: 1, m: 1 }}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={118}
                      />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" width="60%" />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    key={4}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <Box sx={{ px: 1, m: 1 }}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={118}
                      />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" width="60%" />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container columns={{ xs: 2, sm: 3, md: 4 }}>
                  {filtered.length === 0 ? (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: 400,
                        fontSize: "32px",
                      }}
                    >
                      No Products Found
                    </Typography>
                  ) : (
                    filtered.map((item) => (
                      <Grid item xs={1} key={item.id}>
                        <Card
                          sx={{
                            height: "auto",
                            px: 1,
                            m: 1,
                            borderRadius: "15px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            image={item.imgUrl}
                            alt="Product Image"
                          />
                          <CardContent sx={{ height: 80 }}>
                            <Typography component="div" fontSize={20}>
                              {item.productName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.category}
                            </Typography>
                            <Typography variant="h6" component="div">
                              &#8377;{item.productPrice}
                            </Typography>
                          </CardContent>
                          <CardActions
                            sx={{
                              height: 50,
                              justifyContent: "space-between",
                              pb: 0,
                            }}
                          >
                            <Link
                              href={`./product?id=${item.id}`}
                              underline="none"
                            >
                              <Button size="small">More Details</Button>
                            </Link>
                            {/* <IconButton size="large">
                        <Favorite />
                      </IconButton> */}
                          </CardActions>
                        </Card>
                      </Grid>
                    ))
                  )}
                </Grid>
              )}
            </Stack>
          </Stack>
        </>
      )}
      {!loader && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
            margin: "auto",
          }}
        >
          <CircularProgress size={70} />
        </Box>
      )}
    </>
  );
}

export default Home;
