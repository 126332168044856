import { createTheme, ThemeProvider } from "@mui/material";
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import LandingPage from "./components/LandingPage";
import VerifyPage from "./components/Forms/VerifyPage";
import MyListings from "./components/MyListings";
import Product from "./components/Product";
import Form from "./components/Forms/Form";
const theme = createTheme({
  palette: {
    primary: {
      main: "#39C7A5",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/form/*" element={<Form/>} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/MyListings" element={<MyListings />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/verify" element={<VerifyPage />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
